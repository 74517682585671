<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_CONTACT')}} </p>
            </div>
        </div> -->
        <SpainhnNavigationBreadcrumb :navigation-links="links"/>
        <div class="container-fluid px-md-5 px-1 contacto-main">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-2">
                    <div class="d-flex flex-column pt-3 col-12">
                        <div class="row py-5">
                            <div class="col-12 col-lg-6 mx-auto">
                                <p class="text-center spainhn-subtitle-2-black">¿Tienes alguna duda o consulta?</p>
                                <p class="spainhn-text text-center">Si Tienes alguna duda sobre horarios, disponibilidad, reservas o simplemente quieres transmitirnos tu opinión, puedes hacerlo a través del siguiente formulario de contacto:</p>
                            </div>
                        </div> 

                        <div class="row">
                            <div class="col-xl-6 col-md-10 mx-auto">
                                <form @submit.prevent="onSubmit">
                                    <b-form-input 
                                        placeholder="¿Cuál es tu nombre? **" 
                                        class="my-2" 
                                        type="text" 
                                        v-model="nombre"
                                        required/>

                                    <b-form-input 
                                        placeholder="Tu dirección de email **" 
                                        class="my-2" 
                                        type="email"
                                        v-model="email"
                                        required/>

                                    <b-form-input 
                                        placeholder="Tu teléfono **" 
                                        class="my-2" 
                                        type="tel" 
                                        v-model="telefono"
                                        required/>

                                    <b-form-textarea 
                                        rows="5" 
                                        placeholder="Escríbenos tu consulta o sugerencia. Estamos encantados de poder ayudarte. **" 
                                        class="my-2" 
                                        type="tel"
                                        v-model="comentarios"
                                        required/>

                                    <b-form-checkbox 
                                        required
                                        class="my-3 d-flex align-items-baseline"
                                        name="accept-terms">
                                        <p class="ms-2 my-auto">
                                            Acepto la 
                                            <a class="link-dark">
                                                política de privacidad y tratamiento de datos
                                            </a> de Spain Heritage Tours
                                        </p>
                                    </b-form-checkbox>

                                    <div class="w-100">
                                        <mdb-btn 
                                            color="indigo" 
                                            class="spainhn-button w-100 py-3 spainhn-text" 
                                            style="font-size:16pt" 
                                            type="submit"
                                            :disabled="sending"
                                            block>
                                            Enviar
                                        </mdb-btn>
                                    </div>
                                </form>
                            </div>
                        </div>    
                        
                    </div>
                </div>
            </div>
        </div>

        <mdb-container>
            <components-cloud />
        </mdb-container>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import SpainhnNavigationBreadcrumb from '../components/SpainhnNavigationBreadcrumb.vue';
import ComponentsCloud from '../components/ComponentsCloud.vue';

export default {
    components:{
        DefaultLayout, 
        SpainhnNavigationBreadcrumb,
        ComponentsCloud
    },
    data(){
        return{
            nombre: '',
            email: '',
            telefono: '',
            comentarios: '',
            sending: false,
            links: [
                { text: 'INICIO', navigation: true, navigationLink: "/" },
                { text: 'VOLVER', navigation: true, navigationLink: "" }
            ]
        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        async onSubmit(){

            this.sending = true;

            let form = {
                comentarios: this.comentarios,
                telefono: this.telefono,
                nombre: this.nombre,
                email: this.email,
            }

            try{
                await ApiService.sendGeneralInfoEmail( form );
                this.$toast.success("Email enviado al administrador");
            }
            catch( err ){
                this.sending = false;
            }finally{
                this.sending = false;
            }
        }
    },
    setup() {
        
    },
    created(){
       if( this.$route.query.monumentoDeInteres ){
            this.links.push( { text: 'volver' } )
       }
    },
    computed: {

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

    @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
        .margen-estandard {
            padding-left: 3% !important;
            padding-right: 3% !important;
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>
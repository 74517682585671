var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c("div", { staticClass: "container-fluid px-md-5 px-1 contacto-main" }, [
        _c("div", { staticClass: "container-fluid mb-5" }, [
          _c(
            "div",
            { staticClass: "row text-start margen-estandard px-lg-2" },
            [
              _c("div", { staticClass: "d-flex flex-column pt-3 col-12" }, [
                _c("div", { staticClass: "row py-5" }, [
                  _c("div", { staticClass: "col-12 col-lg-6 mx-auto" }, [
                    _c(
                      "p",
                      { staticClass: "text-center spainhn-subtitle-2-black" },
                      [_vm._v("¿Tienes alguna duda o consulta?")]
                    ),
                    _c("p", { staticClass: "spainhn-text text-center" }, [
                      _vm._v(
                        "Si Tienes alguna duda sobre horarios, disponibilidad, reservas o simplemente quieres transmitirnos tu opinión, puedes hacerlo a través del siguiente formulario de contacto:"
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xl-6 col-md-10 mx-auto" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "my-2",
                          attrs: {
                            placeholder: "¿Cuál es tu nombre? **",
                            type: "text",
                            required: "",
                          },
                          model: {
                            value: _vm.nombre,
                            callback: function ($$v) {
                              _vm.nombre = $$v
                            },
                            expression: "nombre",
                          },
                        }),
                        _c("b-form-input", {
                          staticClass: "my-2",
                          attrs: {
                            placeholder: "Tu dirección de email **",
                            type: "email",
                            required: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c("b-form-input", {
                          staticClass: "my-2",
                          attrs: {
                            placeholder: "Tu teléfono **",
                            type: "tel",
                            required: "",
                          },
                          model: {
                            value: _vm.telefono,
                            callback: function ($$v) {
                              _vm.telefono = $$v
                            },
                            expression: "telefono",
                          },
                        }),
                        _c("b-form-textarea", {
                          staticClass: "my-2",
                          attrs: {
                            rows: "5",
                            placeholder:
                              "Escríbenos tu consulta o sugerencia. Estamos encantados de poder ayudarte. **",
                            type: "tel",
                            required: "",
                          },
                          model: {
                            value: _vm.comentarios,
                            callback: function ($$v) {
                              _vm.comentarios = $$v
                            },
                            expression: "comentarios",
                          },
                        }),
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "my-3 d-flex align-items-baseline",
                            attrs: { required: "", name: "accept-terms" },
                          },
                          [
                            _c("p", { staticClass: "ms-2 my-auto" }, [
                              _vm._v(" Acepto la "),
                              _c("a", { staticClass: "link-dark" }, [
                                _vm._v(
                                  " política de privacidad y tratamiento de datos "
                                ),
                              ]),
                              _vm._v(" de Spain Heritage Tours "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "mdb-btn",
                              {
                                staticClass:
                                  "spainhn-button w-100 py-3 spainhn-text",
                                staticStyle: { "font-size": "16pt" },
                                attrs: {
                                  color: "indigo",
                                  type: "submit",
                                  disabled: _vm.sending,
                                  block: "",
                                },
                              },
                              [_vm._v(" Enviar ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("mdb-container", [_c("components-cloud")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }